<template>
  <v-container id="social">
    <v-row>
      <v-col class="_custom-font _text-header">
        SOCIAL

        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="icon in icons" :key="icon.icon" cols="6">
        <v-btn variant="text" block height="100px">
          <v-icon size="x-large" class="orange--text">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainFooter",
  components: {},
  data: () => ({
    icons: [
      {
        icon: "mdi-facebook",
        url: "https://www.facebook.com/andre.gaspari.fisio/",
      },
      {
        icon: "mdi-instagram",
        url: "https://www.instagram.com/andre.gaspari.fisio/",
      },
      {
        icon: "mdi-linkedin",
        url: "https://www.linkedin.com/in/andr%C3%A9-canto-castro-gaspari-63a479115/",
      },
    ],
  }),
};
</script>

<style scoped></style>
