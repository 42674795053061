<template>
  <v-container id="contact">
    <v-row>
      <v-col class="_custom-font _text-header">
        CONTATO

        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-sm-12 col-md-6">
        <v-card class="d-flex my-2">
          <v-row class="justify-space-between">
            <v-card-title class="_custom-font _text-paragraph">
              AMPARO
            </v-card-title>

            <v-card-text class="_custom-font _text-subtitle">
              <v-divider class="primary mb-4" />

              <div>
                <v-icon class="orange--text">mdi-map-marker</v-icon>
                Loja 04 - 724 Av. da Saudade, Amparo, São Paulo
              </div>
              <div>
                <v-icon class="orange--text">mdi-phone</v-icon>
                019 99681-5915
              </div>
              <div>
                <v-icon class="orange--text">mdi-whatsapp</v-icon>
                019 99681-5915
              </div>
            </v-card-text>
          </v-row>

          <v-avatar class="ma-2" rounded="0" size="200">
            <iframe
              width="100%"
              height="100%"
              scrolling="no"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pt&amp;q=R.%20Antonio%20Prado,%20Amparo,%20S%C3%A3o%20Paulo%20-%20Pro%20Corpo+(Pro%20Corpo)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
            </iframe>
          </v-avatar>
        </v-card>
      </v-col>

      <v-col class="col-sm-12 col-md-6">
        <v-card class="d-flex my-2">
          <v-row class="justify-space-between">
            <v-card-title class="_custom-font _text-paragraph">
              SERRA NEGRA
            </v-card-title>

            <v-card-text class="_custom-font _text-subtitle">
              <v-divider class="primary mb-4" />

              <div>
                <v-icon class="orange--text">mdi-map-marker</v-icon>
                Loja 08, 230 Av. Antonio Novais, Serra Negra, São Paulo
              </div>
              <div>
                <v-icon class="orange--text">mdi-phone</v-icon>
                019 3892-1282
              </div>
              <div>
                <v-icon class="orange--text">mdi-whatsapp</v-icon>
                019 99773-0890
              </div>
              <div class="text-caption orange--text">
                * Apenas em Quinta-Feira
              </div>
            </v-card-text>
          </v-row>

          <v-avatar class="ma-2" rounded="0" size="200">
            <iframe
              width="100%"
              height="100%"
              scrolling="no"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pt&amp;q=R.%20Ant%C3%B4nio%20Novaes,%20230%20-%20Est%C3%A2ncia%20Su%C3%AD%C3%A7a+(Sala%2008)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
            </iframe>
          </v-avatar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logo_lugar from "../assets/contact/logo_lugar.jpg";

export default {
  name: "MainContact",
  components: {},
  data: () => ({
    logo_lugar,
    locais_atendimento: [
      {
        title: "",
        address: "",
        phone: "",
        whatsapp: "",
      },
    ],
  }),
};
</script>
