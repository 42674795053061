<template>
  <v-container id="techniques">
    <v-row>
      <v-col class="_custom-font _text-header">
        MÉTODOS

        <v-divider class="primary" />
      </v-col>
    </v-row>

    <v-row>
      <!-- All the techniques in order -->
      <v-col>
        <v-expansion-panels v-if="$vuetify.breakpoint.xs">
          <v-expansion-panel v-for="(item, i) in techniques" :key="i">
            <v-expansion-panel-header class="_custom-font custom-text-h4">
              {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="_custom-font _text-paragraph">
              <v-divider class="primary mb-4" />
              {{ item.text }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-tabs v-else vertical>
          <v-tab v-for="(item, i) in techniques" :key="i" class="text-left">
            {{ item.title }}
          </v-tab>

          <v-tab-item v-for="(item, i) in techniques" :key="i">
            <v-card flat>
              <v-container class="d-flex">
                <v-row>
                  <v-col
                    :cols="$vuetify.breakpoint.sm ? '12' : '4'"
                    class="right-blend"
                  >
                    <v-card-text
                      class="text-justify _custom-font _text-paragraph"
                    >
                      {{ item.text }}

                      <v-divider class="ma-4" />

                      Benefícios:
                      <ul>
                        <li v-for="(benefit, j) in item.benefits" :key="j">
                          {{ benefit }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-col>
                  <v-col v-if="!$vuetify.breakpoint.sm">
                    <v-img class="image-fit" :src="item.image" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import liberacao_miofascial from "../assets/techniques/liberacao_miofascial.jpeg";
import agulhamento_seco from "../assets/techniques/agulhamento_seco.jpg";
import kinesio_tape from "../assets/techniques/kinesio_tape.jpeg";
import ventosaterapia from "../assets/techniques/ventosaterapia.jpeg";
import tiq from "../assets/techniques/tiq.jpeg";

export default {
  name: "MainTechniques",
  data: () => ({
    selectedItem: 1,
    techniques: [
      {
        title: "Agulhamento a Seco",
        text: `O Agulhamento a Seco (Dry Needling) é uma técnica que consiste a colocação de uma agulha, sem medicação, sobre o ponto gatilho, e gerar um estímulo elétrico sobre ele, causando assim o relaxamento da musculatura contraída e tirando do padrão de contração muscular anormal. Os pontos-gatilhos são pontos hipersensíveis de dor localizados nos músculos e no tecido conjuntivo. Estão relacionados com um aumento da tensão da musculatura e podem ser liberados com o relaxamento dessa musculatura.`,
        image: agulhamento_seco,
        benefits: [
          "Redução de dor muscular",
          "Melhora no movimento articular",
          "Diminuição de Inflamações locais",
        ],
      },
      {
        title: "Kinesio Tape",
        text: "O kinesio tape é uma técnica de bandagem funcional que consiste na aplicação de tiras adesivas no corpo com o objetivo de acelerar e potencializar a recuperação de lesões, alívio de dores e também como uma alternativa para a reabilitação das ações motoras de músculos, articulações e ligamentos.",
        image: kinesio_tape,
        benefits: [
          "Alívio de dores e tensões musculares",
          "Diminuição de edemas",
          "Estabilização das articulações lesionadas",
        ],
      },
      {
        title: "Liberação Miofascial",
        text: `A liberação miofascial consiste em uma técnica de alívio de dores musculares. O grande uso de uma musculatura, má postura ou mesmo stress emocional podem levar à alteração de tensão da fáscia (tecido fibroso que recobre os músculos do corpo). O relaxamento da musculatura tensionada e a liberação dos pontos de maior tensão fascial ajudam a melhorar o deslocamento da fáscia sobre o músculo, melhorando a elasticidade, flexibilidade muscular, coordenação e até ativação muscular. A liberação miofascial pode ser feita manualmente ou com o uso de instrumentos, deslizando-os sobre a pele.`,
        image: liberacao_miofascial,
        benefits: [
          "Alívio de dores crônicas, tensionais, pós-treino",
          "Relaxamento muscular",
          "Maior mobilidade das articulações",
        ],
      },
      {
        title: "TIQ (Terapia Instrumental Quiroprática)",
        text: "A Terapia Instrumental Quiropráxica, ou TIQ, é uma área da Quiropraxia que ao invés de utilizar as mãos para a realização dos ajustes, utiliza um instrumento. O TIQ é posicionado na articulação que necessita ser ajustada e a mobiliza, devolvendo o seu movimento à normalidade. O TIQ é um recurso de ouro em casos nos quais a Quiropraxia convencional fica limitada, como osteoporose, o medo dos ajustes manuais, casos muito agudos, dentre outros.",
        image: tiq,
        benefits: [
          "Alívio da tensão articular",
          "Relaxamento Muscular",
          "Melhora na amplitude do movimento",
        ],
      },
      {
        title: "Ventosaterapia",
        text: "A colocação das ventosas sobre os pontos de tensão musculares aumentam o fluxo de sangue sobre aquela região, melhorando a oxigenação da musculatura e relaxando a musculatura contraída. O relaxamento da musculatura também gera uma diminuição da tensão da fáscia que recobre aquela musculatura, melhorando a dor e o movimento muscular.",
        image: ventosaterapia,
        benefits: [
          "Aumento da circulação sanguinea local",
          "Eliminação de pontos gatilhos",
          "Desentoxicação dos tecidos",
        ],
      },
    ],
  }),
};
</script>

<style scoped>
.image-fit {
  max-width: 100%;
  max-height: inherit;
}

.right-blend {
  box-shadow: 0px 0px 50px 70px white !important;
  z-index: 1;
}

.custom-text-h4 {
  font-size: 1.25rem;
}
</style>
