<template>
  <v-container>
    <div
      v-if="$vuetify.breakpoint.xs"
      class="d-flex justify-space-between align-center _custom-font _text-paragraph"
    >
      <div>
        <v-btn text tile :ripple="false" @click="smoothScroll('#about')">
          SOBRE
        </v-btn>

        <v-divider class="primary" />

        <v-btn text tile :ripple="false" @click="smoothScroll('#techniques')">
          MÉTODOS
        </v-btn>
      </div>

      <v-img :src="this.logo" class="photo-max-width-xs ma-2" />

      <div class="text-right ma-2">
        <v-btn text tile :ripple="false" @click="smoothScroll('#contact')">
          CONTATO
        </v-btn>

        <v-divider class="primary" />

        <v-btn text tile :ripple="false" @click="smoothScroll('#contact')">
          SOCIAL
        </v-btn>
      </div>
    </div>

    <div
      v-else
      class="d-flex justify-center align-center _custom-font _text-paragraph"
    >
      <v-btn text tile :ripple="false" @click="smoothScroll('#about')">
        SOBRE
      </v-btn>

      <v-divider vertical class="primary ma-4" />

      <v-btn text tile :ripple="false" @click="smoothScroll('#techniques')">
        MÉTODOS
      </v-btn>

      <v-divider vertical class="primary ma-4" />

      <v-img :src="this.logo" class="photo-max-width ma-2" />

      <v-divider vertical class="primary ma-4" />

      <v-btn text tile :ripple="false" @click="smoothScroll('#contact')">
        CONTATO
      </v-btn>

      <v-divider vertical class="primary ma-4" />

      <v-btn text tile :ripple="false" @click="smoothScroll('#social')">
        SOCIAL
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import logo from "@/assets/logo.png";

export default {
  name: "MainHeader",
  methods: {
    smoothScroll(where) {
      document.querySelector(where).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  data: () => ({
    logo,
    techniques: [
      { title: "Ventosaterapia", to: "" },
      { title: "Acupuntura", to: "" },
      { title: "Agulhamento a seco", to: "" },
      { title: "Kinesio Tape", to: "" },
      { title: "Kinesio Tape", to: "" },
    ],
  }),
};
</script>

<style scoped>
.photo-max-width {
  max-width: 17vw;
  min-width: 200px;
}

.photo-max-width-xs {
  max-width: 20vw;
  min-width: 150px;
}
</style>
