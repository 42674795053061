<template>
  <div class="fixed-size">
    <slot />
  </div>
</template>

<script>
export default {
  name: "fullPageView",
};
</script>

<style scoped>
.fixed-size {
  min-width: 100vw !important;
  width: 100vw !important;
  height: 100vh !important;
  min-height: 100vh !important;
  max-height: 100vh !important;

  display: flex;
  justify-content: center;
  align-items: center;

  scroll-snap-align: center;
}
</style>
